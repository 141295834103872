.wrapper {
  position: relative;
  width: 100%;
  height: 52px;
}
.placeholder {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 17px;
  line-height: 20px;
  color: #3b3e41;
  transition: all 0.2s;
  transform: translate3d(15px, 16px, 0);
  pointer-events: none;
}
.placeholderAway {
  font-size: 13px;
  line-height: 15px;
  transform: translate3d(9px, 4px, 0);
}
.toggle {
  position: relative;
  width: 100%;
  height: 52px;
  font-size: 17px;
  line-height: 20px;
  color: #000000;
  background: #ffffff;
  border-radius: 4px;
  border: none;
  padding: 12px 15px 0;
  text-align: left;
  border-top: 1px solid #d4d4d4;
}
.toggle:focus,
.toggleOpen {
  outline: 2px solid #17b4ea;
}
.arrow {
  position: absolute;
  top: 50%;
  right: 12px;
  border: 6px solid transparent;
  border-top-color: #3b3e41;
  transform: translate3d(0, -25%, 0);
  transition: all 0.2s;
  transform-origin: center 25%;
}
.arrowOpen {
  transform: translate3d(0, -25%, 0) rotate(180deg);
}
.toggleOpen ~ .menu {
  outline: 2px solid #d4d4d4;
}
.menu {
  position: absolute;
  top: 112%;
  width: 100%;
  max-height: 138px;
  list-style: none;
  border-radius: 4px;
  margin: 0;
  padding: 0;
  background: #ffffff;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 1;
}
.menu::-webkit-scrollbar {
  width: 4px;
  background-color: transparent;
}
.menu::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.51);
  border-radius: 9999px;
}
.item {
  display: flex;
  align-items: center;
  padding: 0 18px;
  height: 32px;
  color: #3b3e41;
  cursor: pointer;
}
.item:first-child {
  margin-top: 4px;
}
.item:last-child {
  margin-bottom: 4px;
}
